<template>
<!-- 申请样品订单页 -->
  <div id="page">
    <div class="nav">
      <HeaderBase :sear="'hide'"/>
    </div>

    <div id="content">
      <div class="message_body">
        <h1>{{$t('m.buy.Confirm_receipt_information')}}</h1>
        <div class="show_mess">
          <!-- 确认订单 -->
          <div class="mess_title">
            <ul class="show_ul">
              <li class="show_li li_name">{{$t('m.buy.commodity_information')}}</li>
              <li class="show_li li_size">{{$t('m.buy.commercial_specification')}}</li>
              <li class="show_li li_stock">{{$t('m.buy.sample_stock')}}</li>
              <li class="show_li li_price">{{$t('m.buy.unit_price')}}</li>
              <li class="show_li li_quantity">{{$t('m.buy.sample_quantity')}}</li>
              <!-- <li class="show_li li_quantity">{{'库存'}}</li> -->
              <li class="show_li li_total">{{$t('m.buy.total_prices')}}</li>
            </ul>
          </div>
          <!-- 确认订单信息 -->
          <div class="shop_mess">
            <ul class="show_ul">
              <li class="show_li li_name">
                <div class="namebody">
                  <img :src="shopdata.pmsSkuStock.pic" alt="">
                  <div class="name_time">
                    <p>{{shopdata.pmsProduct.name}}</p>
                    <span>{{shopdata.pmsProduct.deliveryTime}}</span>
                  </div>
                </div>
              </li>
              <li class="show_li li_size">
                  <el-select
                  size="small" value-key="id"
                  v-model="guigevalue"
                  @change="guigevalueChang"
                  :placeholder="$t('m.reservation_varietiy_details.Please_select_specifications')">
                    <el-option
                      v-for="(i,k) in skuStockList" :key="k"
                      :label="$t('m.reservation_varietiy_details.specification')+`:`+i.spData[0].value"
                      :value="i">
                    </el-option>
                  </el-select>
                </li>
              <li class="show_li li_stock">
                {{shopdata.pmsSkuStock.stock}}
                </li>
              <li class="show_li li_price">
                {{shopdata.pmsProduct.currencySymbol+shopdata.pmsProduct.price+'/'+shopdata.pmsProduct.unit}}
                </li>
              <li class="show_li li_quantity">
                {{quantity.value}}
              </li>
              <!-- <li class="show_li li_quantity">
                {{shopdata.pmsSkuStock.stock}}
              </li> -->
              <li class="show_li li_total">
                {{
                  shopdata.pmsProduct.currencySymbol+(shopdata.pmsProduct.price * quantity.value).toFixed(2)
                }}</li>
            </ul>
          </div>

          <!-- 预提日期 -->
          <div class="shop_type" v-if="previewStatus!=0">
            <span class="type_title">{{$t('m.buy.Advance_the_delivery_date')}}</span>
            <div class="setime">
              <!-- <span class="yutitime">预提日期:</span> -->
              <div class="timeshow">
                <span class="showyuti" v-for="i in timeTiYushow" :key="i"
                >{{i}}
                </span>
              </div>
            </div>
          </div>
          <!-- 提货信息 -->
          <div class="shop_type" v-if="takeWayShow">
            <span class="type_title">{{$t('m.buy.Delivery_type')}}</span>
            <div class="radios" v-if="takeWayShow">
              <el-radio v-model="shopsite.radio" label="1">{{$t('m.buy.logistics')}}</el-radio>
              <el-radio v-model="shopsite.radio" label="0">{{$t('m.buy.Take_their')}}</el-radio>
            </div>
            <span class="type_title tharea_title" v-if="shopsite.radio === '1'&&previewStatus==0">{{$t('m.buy.Delivery_only')}}{{wuliuValue}}</span>
            <span class="type_title tharea_title" v-if="shopsite.radio === '0'&&previewStatus==0">{{$t('m.buy.place_of_delivery')}}</span>
            <div class="tharea" v-if="shopsite.radio === '0'">
              <span>{{shopdata.pmsSiteEntity.name}}</span>
            </div>
          </div>
          <!-- 预提方式 -->
          <div v-show="takeWayShow && takeWayEntities" v-if="takeWayShow&&previewStatus!=0"
          class="shop_type">
            <span class="type_title">{{$t('m.reservation_varietiy_details.Delivery_method')}}</span>
            <el-select
                v-model="ytValue"
                :placeholder="$t('m.reservation_varietiy_details.Select_withholding_mode')"
                @change="getYtValue"
            >
              <el-option
                  v-for="item in takeWayEntities"
                  :key="item.id"
                  :label="item.spec+shopdata.pmsProduct.currencySymbol+item.price"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 物流信息 -->
          <div v-show="shopsite.radio=='1'?true:false"
          v-if="takeWayShow"
           class="shop_site">
            <wu-liu :wuliumes="wuliumessage"></wu-liu>
          </div>
          <!-- 发票信息 -->
          <div class="shop_invoice">
            <Invoice :invoicemsg="invoicemessage" />
          </div>

          <div class="tishi" >
            <p v-if="previewStatus!=0">
              <el-icon><warning-filled /></el-icon>
              <span>{{$t('m.buy.Please_make_sure_it_is_before_the_delivery_date')}}
                {{this.shopdata.pmsProductPresaleExtraEntity.advancePikegoodsDaysBegin}}
                -
                {{this.shopdata.pmsProductPresaleExtraEntity.advancePikegoodsDaysEnd}}
                {{$t('m.buy.days_in_advance_of_delivery')}}</span>
            </p>
            <div class="tiaoyue">
              <el-checkbox  v-model="checkedTiaoYue" size="mini">
                {{$t('m.map.I_have_read_and_agree')}}
                <span class="tiaokuan" @click="previewStatus!=0?readContract(3):readContract(1)">《{{$t('m.map.purchases_and_sales_contract')}}》</span>、
                <span class="tiaokuan" @click="readContract(4)">《{{$t('m.map.trade_rules')}}》</span>、
                <span class="tiaokuan" @click="readContract(5)">《{{$t('m.map.quality_standard')}}》</span>
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>
      <!-- 支付金额 -->
      <div class="total_price">
        <div class="price_body">
          <div class="price_title">
            {{$t('m.buy.aggregate')}}
          </div>

          <div class="price_show">
            <el-descriptions >
              <el-descriptions-item :label="$t('m.personalcenter_myorder.logistics_fees')+'：'" v-if="previewStatus==0&&shopsite.radio==='1'">
                {{shopdata.pmsProduct.currencySymbol+wuliufreight}}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('m.personalcenter_myorder.Accrued_expenses')+'：'" v-if="depositShow">
                {{shopdata.pmsProduct.currencySymbol+(freight*quantity.value)}}
              </el-descriptions-item>
              <!-- <el-descriptions-item label="应付总金额：" v-if="previewStatus==0">
                {{shopdata.pmsProduct.currencySymbol+(comPrice)}}
              </el-descriptions-item> -->
              <el-descriptions-item :label="$t('m.buy.Total_amount_payable')+'：'">
                {{shopdata.pmsProduct.currencySymbol}}{{comPrice+(previewStatus==0&&shopsite.radio==='1'?wuliufreight:0)}}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('m.map.Deposit_payable')+'：'" v-if="!depositShow&&previewStatus!=0">
                {{shopdata.pmsProduct.currencySymbol
                +((comPrice / 100) * shopdata.pmsProduct.prePayRate).toFixed(2)}}
              </el-descriptions-item>
            </el-descriptions>
            <div class="btn_submit">
              <el-button native-type="submit"
               @click="submitData" :disabled="!checkedTiaoYue"
               :loading="loading"
               size="medium">{{$t('m.buy.outright_purchase')}}</el-button>
               <!-- <el-button native-type="submit"
               @click="rzDialogVisible=true"
               :loading="loading"
               size="medium">{{$t('m.buy.Finance_purchase')}}</el-button> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rongzi_buy">
      <el-dialog
        v-model="rzDialogVisible"
        width="600px"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center
      >
        <div class="financingPay">
          <text class="iconfont icon-guanbi"   @click="close()"></text>
          <div class="title">请选择融资银行</div>
          <div class="bankBox">
            <div class="bankItem" v-for="(item,index) in bankInfo" :key="index">
              <div class="banklogo">
                <img :src="item.logo" mode="aspectFill">
              </div>
              <div class="choosebox">
                <!-- <label class="radio" @click="chooseBank(index)">
                  <radio value="Chinabank"  :checked="bankCurrent==index"/><text>{{item.txt}}</text>
                </label> -->
                <el-radio v-model="Chinabank" :label="item.name">{{item.txt}}</el-radio>
              </div>
            </div>
          </div>
          <div class="finabtn">申请融资</div>
          <div class="agreeTerms">
            <!-- <label class="radio">
              <radio  /><text>我已仔细阅读申请合约，并同意其中所有条款</text>
            </label> -->
            <el-checkbox  v-model="checkedRegiste" size="mini">
              我已仔细阅读申请合约，并同意其中
              <span class="tiaokuan">所有条款</span>
            </el-checkbox>
          </div>
        </div>
      </el-dialog>
    </div>

    <div id="foot">
      <Footer/>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
// import { useRoute } from 'vue-router'

import WuLiu from './WuLiu.vue'
import Invoice from './Invoice.vue'
import Footer from '../index/Footer.vue'
import { WarningFilled } from '@element-plus/icons'

// import tomessage from '../../tools/tomessage.js'
import HeaderBase from '../../components/HeaderBase.vue'

import { ElLoading, ElMessage } from 'element-plus'
import { getStore, setStore } from '../../tools/storage'
import emitter from '../../tools/bus.js'
import http from '../../api/http.js'
import moment from 'moment'

// 查样品信息
const detailBySn = (data) => {
  return http.get('/mall-portal/product/detailBySn', data)
}
// 确认订单
const datePresaleGenerateConfirmOrder = (data) => {
  return http.post('/mall-order/order/datePresaleGenerateConfirmOrder', data, true)
}
var loading = ElLoading.service({
  lock: true,
  text: '请稍等...'
})
export default {
  components: { WuLiu, Invoice, WarningFilled, HeaderBase, Footer },
  name: 'Accounts',
  data () {
    return {
      previewStatus: '', // 判断是预售还是现货
      shopdata: reactive({}),
      quantity: reactive({
        value: 1,
        max: 10,
        min: 1
      }),
      shopsite: {
        radio: '1',
        time1: ''
      },
      timeYushow: [], // 选择的预提日期
      timeTiYushow: [], // 可以预提时间
      presaleWayId: '', // 预提方式id
      takeWayShow: '', // 是否选择显示预提方式 + 不显示物流自提
      takeWayEntities: [], // 预提方式
      ytValue: '', // 选择的预提方式
      wuliumessage: [], // 收货地址信息
      wiliuChose: 0, // 选择的第几个地址
      invoicemessage: [], // 发票信息
      invoiceChose: 0, // 选定的发票
      posturl: '', // 请求接口地址
      depositShow: '', // 是否在预提时间内 定金展示
      // comPrice: 0,
      skuStockList: reactive([]),
      guigevalue: '',

      freight: ref(''),
      wuliufreightMoney: '', // 现货物流单价
      wuliufreight: '', // 现货物流合计费用
      wuliuValue: '',
      loading: false,
      checkedTiaoYue: ref(false),

      // 融资购买
      rzDialogVisible: ref(false),
      bankInfo: [
        { name: '中国银行', logo: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/logo/abc_bank.png', txt: '中国银行上海分行，年化利率0.0201%' },
        { name: '光大银行', logo: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/logo/icon_ICBC.png', txt: '光大银行上海分行，年化利率0.0201%' },
        { name: '建设银行', logo: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/logo/CCI_bank.png', txt: '建设银行上海分行，年化利率0.0201%' }
      ],
      Chinabank: ref(''),
      checkedRegiste: ref(false)
    }
  },
  computed: {
    comPrice: {
      get: function () {
        const mount = this.shopdata.pmsProduct.price *
        this.quantity.value +
        this.freight * this.quantity.value
        // this.shopdata.payAmount
        // console.log((this.shopdata.pmsProduct.price * this.quantity.value).toFixed(2))
        // console.log(this.freight * this.quantity.value)
        // console.log(mount.toFixed(2))
        return Number(mount.toFixed(2))
      }
    }
  },
  methods: {
    submitData () { // 提交订单
      if ((this.wiliuChose === '' || this.wiliuChose === null) && this.shopsite.radio === '1') {
        ElMessage.warning({
          message: '请添加收货地址！！'
        })
        return
      }
      if (this.quantity.max === 0) {
        ElMessage.warning({
          message: '商品库存为0！'
        })
        return
      }
      const loadingpage = this.$loading({
        lock: true,
        text: '生成订单中'
      })
      // 样品现货
      const postdata = {
        productId: this.shopdata.pmsProduct.id, // 商品id
        productQuantity: this.quantity.value, // 购买数量
        productSkuId: this.shopdata.pmsSkuStock.id, // skuID
        payType: null, // 支付方式
        orderType: 10, // 订单类型 0：正常，1：秒杀，2：转售，3：预购 10：样品
        receiverAddressId: this.shopsite.radio === '1' ? this.wiliuChose : null, // 收货地址id（买家）
        takeGoodsType: this.shopsite.radio === '1' ? 1 : 0, // 提货类型 0自提1物流
        totalAmount: Number(this.shopdata.pmsProduct.price * this.quantity.value).toFixed(2),
        invoiceId: this.invoiceChose // 发票id
      }
      this.posturl = '/mall-order/order/generateOrder'
      http.post(this.posturl, postdata, false)
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            const str = []
            let payAmount = 0
            const payStr = '￥'
            str.push(res.data.data.orderSn)
            payAmount += res.data.data.payAmount
            // setStore('orderSns', str.toString())
            // console.log(payAmount)
            const datecr = res.data.data.createTime
            setStore('settleAccTime', moment(datecr).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'))
            this.$router.push(
              {
                path: '/buy/settleaccounts',
                query: {
                  orderSn: encodeURIComponent(str.toString()),
                  payAmount: encodeURIComponent(JSON.stringify(payStr + payAmount))
                }
              }
            )
          } else {
            ElMessage.error({
              message: res.data.msg
            })
          }
        }).catch(err => {
          console.log(err)
        })
      setTimeout(() => {
        this.loading = false
        loadingpage.close()
      }, 1000)
    },
    async getXianHuoMes () { // 处理样品现货数据方法
      // console.log(mes)
      const userId = (getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')) : null
      const data = {
        productSn: this.$route.query.sampleNumber,
        userId: userId ? userId.id : null
      }
      await detailBySn(data).then((res) => {
        console.log(res.data.data)
        // res.data.data.skuStockList.spData = JSON.parse(res.data.data.skuStockList.spData)
        this.skuStockList = res.data.data.skuStockList
        for (const i of this.skuStockList) {
          i.spData = JSON.parse(i.spData)
        }
        this.guigevalue = this.skuStockList[0]
        // console.log(this.skuStockList)
        this.ConfirmOrder(this.guigevalue)
      }).catch((err) => {
        console.log(err)
      })
    },
    guigevalueChang (val) {
      console.log(val)
      this.ConfirmOrder(val)
    },
    ChangeNums () { // 改变数量触发方法
      this.wuliufreight = Number((this.wuliufreightMoney * this.quantity.value).toFixed(1))
    },
    async ConfirmOrder (thisStock) { // 初始选择的规格
      // const thisStock = res
      const Orderdata = [{
        SkuId: thisStock.id,
        SkuCode: thisStock.skuCode,
        number: 1
      }]
      await datePresaleGenerateConfirmOrder(Orderdata).then((result) => {
        console.log(result.data.data)
        if (result.data.code === 200) {
          const mes = result.data.data
          this.shopdata = result.data.data
          mes.pmsSkuStock.spData = JSON.parse(mes.pmsSkuStock.spData)
          this.wuliumessage = mes.logisticsVos // 收货地址信息
          this.invoicemessage = mes.invoiceVos // 发票信息
          this.quantity.value = mes.number > mes.pmsSkuStock.stock ? mes.pmsSkuStock.stock : mes.number // 选择了几个
          this.quantity.max = mes.pmsSkuStock.stock // 最多可买,当前的规格总库存
          if (mes.pmsProduct.freightChina) {
            // 国内
            this.wuliuValue = '国内'
            this.wuliufreightMoney = mes.pmsProduct.freightChina.split('/')[0]
          } else if (mes.pmsProduct.freightAbroad) {
            // 国外
            this.wuliuValue = '国外'
            this.wuliufreightMoney = mes.pmsProduct.freightAbroad.split('/')[0]
          }
          this.ChangeNums()
          this.takeWayShow = true
        }
        loading.close()
      }).catch((err) => {
        console.log(err)
      })
    },
    changeTime () {
      const now = moment(new Date()).format('YYYY-MM-DD')
      const overTime = this.shopdata.pmsSkuStock.spData[0].value
      // console.log(now)
      const lastbegin = moment(overTime, 'YYYY-MM-DD')
        .subtract(this.shopdata.pmsProductPresaleExtraEntity.advancePikegoodsDaysBegin, 'days')
        .format('YYYY-MM-DD')
      const lastend = moment(overTime, 'YYYY-MM-DD')
        .subtract(this.shopdata.pmsProductPresaleExtraEntity.advancePikegoodsDaysEnd, 'days')
        .format('YYYY-MM-DD')

      // console.log(lastbegin, lastend)
      this.timeTiYushow.push(lastend)
      this.timeTiYushow.push('—')
      this.timeTiYushow.push(lastbegin)
      const timestatus = moment(now).isBetween(lastend, lastbegin, 'days', '[]')

      // console.log(timestatus)
      return timestatus
    },
    getYtValue (val, item) {
      // console.log(val)
      for (const i of this.shopdata.pmsProductPreTakeWayEntities) {
        if (i.id === val) {
          this.freight = i.price
          break
        }
      }
    },
    readContract (code) {
      const fileUrl = code === 1
        ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E7%8E%B0%E8%B4%A7%E8%B4%AD%E9%94%80%E5%90%88%E5%90%8C.pdf'
        : code === 2
          ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E8%BD%AC%E5%94%AE%E8%B4%AD%E9%94%80%E5%90%88%E5%90%8C.pdf'
          : code === 3
            ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E9%A2%84%E5%94%AE%E8%B4%AD%E9%94%80%E5%90%88%E5%90%8C.pdf'
            : code === 4
              ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E8%B4%B8%E6%98%93%E7%BB%86%E5%88%99.pdf'
              : 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E8%B4%A8%E9%87%8F%E6%A0%87%E5%87%86.pdf'
      window.open(fileUrl)
    }
  },
  created () {
    // this.shopdata = JSON.parse(getStore('sampleordermessage'))
    this.previewStatus = this.$route.query.previewStatus
    // console.log(typeof this.previewStatus)
    if (this.$route.query.previewStatus === '1') {
      console.log('预售')
      this.getYushouMes(this.shopdata)
    } else {
      console.log('现货')
      this.getXianHuoMes()
    }
  },
  mounted () {
    // this.getXianHuoMes()
    emitter.on('wuliumesArea', (data) => {
      this.wiliuChose = data
    })
    emitter.on('invoiceMsg', (data) => {
      console.log(data)
      this.invoiceChose = data
      // console.log(this.invoiceChose)
    })
    // this.shopdata = JSON.parse(this.$route.params.prodata)
  },
  setup (props, ctx) {
    // const invoiceGoData = ref('')

    // console.log(route.query.data)

    return {

    }
  }
}
</script>

<style lang="scss">
.el-checkbox .is-checked .el-checkbox__inner {
  background-color: #C6824E;
  border: 1px solid #C6824E;
}
.el-checkbox .is-checked .el-checkbox__inner::after {
  border-color: #ffffff;
}
.el-checkbox .is-checked .el-checkbox__inner span {
  color: #C6824E;
}
</style>

<style lang="scss">
@import '../../style/viriables.scss';

#page{
  background: #F2EFED;
  width: 100%;
  // height: 100%;
  .nav{
    // height: 30px;
    width: 100%;
    background: $divbgColor;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    #search{
      width: 100%;
      height: 120px;
      background: #ffffff;
      .logosearch{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        z-index: 99;
        justify-content: left;
        align-items: center;
        #logo{
          img{
            margin-right: 80px;
            z-index: 9999;
          }
        }
      }
    }
  }
  #content{
    margin-top: 10px;
    .message_body{
      margin: 0 auto;
      padding: 20px;
      background: $divbgColor;
      width: 1200px;
      margin-bottom: 10px;
      box-sizing: border-box;
      h1{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        box-sizing: border-box;
      }
      .show_mess{
        .mess_title{
          padding: 20px;
          .show_ul{
            width: 100%;
            height: 50px;
            background: #F7F7F7;
            display: flex;
            align-items: center;
            .li_name{
              width: 400px;
            }
            .li_size{
              width: 176px;
            }
            .li_stock{
              width: 100px;
            }
            .li_price{
              width: 160px;
            }
            .li_quantity{
              width: 240px;
            }
            .li_total{
              flex: 1;
            }
          }
        }
        .shop_mess{
          padding: 20px;
          padding-top: 0;
          border-bottom: 1px solid #EAEAEA;
          .show_ul{
            // padding: 20px 0;
            // box-sizing: border-box;
            display: flex;
            align-items: center;
            .li_name{
              width: 400px;
              .namebody{
                display: flex;
                align-items: center;
                img{
                  height: 80px;
                  width: 80px;
                }
                .name_time{
                  margin-left: 10px;
                  p{
                    width: 310px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    line-height: 39px;
                    color: #333333;
                  }
                  span{
                    color: #999999;
                  }
                }
              }
            }
            .li_size{
              width: 176px;
              .el-select{
                width: 120px;
              }
            }
            .li_price{
              width: 160px;
            }
            .li_stock{
              width: 100px;
            }
            .li_quantity{
              width: 240px;
            }
            .li_total{
              flex: 1;
            }
          }
        }
        .shop_type{
          padding: 0 20px;
          display: flex;
          border-bottom: 1px solid #EAEAEA;
          .type_title{
            min-width: 120px;
            margin-right: 10px;
            font-size: 14px;
            line-height: 40px;
            color:#999999;
          }
          .radios{
            margin-right: 60px;
          }
          .tharea_title{
            width: 80px;
          }
          .tharea{
            line-height: 40px;
            color:#999999;
            span{
              color: #333333;
            }
          }
          .setime{
            display: flex;
            align-items: center;
            .yutitime{
              width: 80px;
              color: #333333;
            }
            .timeshow{
              // flex: 1;
              .el-input{
                border: none;
                background: 0;
              }
              .showyuti{
                margin-right: 5px;
              }
            }
          }
        }
        .shop_site{
          padding-bottom: 20px;
          border-bottom: 1px solid #EAEAEA;
        }
        .shop_invoice{
          border-bottom: 1px solid #EAEAEA;
        }
        .tishi{
          // height: 50px;
          margin-top: 20px;
          padding: 0 20px;
          p{
            line-height: 50px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #C6824E;
            display: flex;
            align-items: center;
            span{
              margin-left: 5px;
            }
          }
          .tiaoyue{
            .tiaokuan{
              color: #C6824E;
            }
          }
        }
      }
    }
    .total_price{
      margin: 0 auto;
      padding: 20px;
      background: $divbgColor;
      width: 1200px;
      box-sizing: border-box;
      margin-bottom: 40px;
      .price_body{
        .price_title{
          padding-left: 20px;
          padding-bottom: 10px;
          line-height: 39px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #C6824E;
          border-bottom: 1px solid #EAEAEA;
        }
        .price_show{
          padding: 20px;
          padding-top: 10px;
          // width: 260px;
          display: block;
          .el-descriptions{
            max-width: 800px;
            .el-descriptions__body{
              background: none;
              tr{
                .el-descriptions__cell{
                  display: flex;
                  font-size: 14px;
                  padding-bottom: 6px;
                  justify-content: center;
                  .el-descriptions__label{
                    text-align: justify;
                    text-align-last:justify;/* ie9*/
                    -moz-text-align-last:justify;/*ff*/
                    -webkit-text-align-last:justify;/*chrome 20+*/
                    min-width: 120px;
                    color: #999999;
                    margin: 0;
                    text-align: justify;
                  }
                  .el-descriptions__content{
                    flex: 1;
                    color: #C6824E;
                    .phonenumshow{
                      color: #333333;
                      span{
                        cursor: pointer;
                        color: #999999;
                      }
                    }
                    span{
                      margin-left: 20px;
                    }
                  }
                }
              }
            }
          }
          .btn_submit{
            float: right;
            .el-button--medium{
              z-index: 99;
              float: right;
              margin-top: -20px;
              background: linear-gradient(0deg, #280E01 0%, #673000 100%);
              span{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FEFEFE;
              }
              .el-icon{
                color: #FEFEFE;
              }
            }
          }
        }
      }
    }
  }
  .rongzi_buy{
    .el-overlay{
      .el-overlay-dialog{
        .el-dialog{
          .el-dialog__body{
            .financingPay{
              width: 100%;
              // height: 520rpx;
              overflow: hidden;
              position: relative;
              padding:40px 40px;
              box-sizing: border-box;
              .icon-guanbi{
                font-size: 36px;
                position: absolute;
                top:20px;
                right: 20px;
              }
              .title{
                width: 100%;
                text-align: center;
                font-size: 18px;
              }
              .bankBox{
                width: 100%;
                margin-top: 20px;
                .bankItem{
                  width: 100%;
                  height: 160px;
                  padding:20px 20px 0;
                  box-sizing: border-box;
                  border-bottom: 1px solid #eee;
                  .banklogo{
                    width: 280px;
                    image{
                      width: 100%;
                      height: 66px;
                    }
                  }
                  .choosebox{
                    width: 100%;
                    font-size:18px;
                    color: #999;
                  }
                }
              }
              .finabtn{
                width: 272px;
                height: 42px;
                line-height: 42px;
                margin:30px auto;
                text-align: center;
                border-radius: 10px;
                background-color: #3E1604;
                color: white;
                cursor: pointer;
                font-size: 20px;
              }
              .agreeTerms{
                margin:0 auto;
                text-align: center;

                font-size: 18px;
                .tiaokuan{
                  color: #C6824E;
                }
                .el-checkbox{
                  .el-checkbox__input{
                    background:#C6824E ;
                  }
                  .el-checkbox__label{
                    color: #333333;
                  }
                  .is-checked{
                    color: #C6824E;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #foot{
    background: #280E01;
  }
}
</style>
